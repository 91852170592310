.academicDbRankByType-card-title {
    font-size: var(--fs-h4);
    color: var(--color-text-primary);
}

.academicDbRankByType-stack-wrapper {
    overflow-y: auto;
    min-height: 100px;
    max-height: 264px;
}

.academicDbRankByType-stack-wrapper::-webkit-scrollbar {
    width: 8px;
}

.academicDbRankByType-stack-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.academicDbRankByType-stack-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.academicDbRankByType-card-rowItem:hover {
    background-color: var(--color-base);
}

.academicDbRankByType-card-bodyText {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
}
