.academicDbUsersTotal-mainDiv{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.academicDbUsersTotal-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-md);
    padding: 16px;
}

.academicDbUsersTotal-boxText{
    font-size: var(--fs-h6);
    line-height: calc(var(--fs-h6)*1.3);
    color: var(--color-text-primary);
}

.academicDbUsersTotal-countNumber{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
    background-color: var(--color-secondary-light);
    color: var(--color-text-tertiary);
    padding: 4px 16px;
    border-radius: var(--border-radius-md);
}