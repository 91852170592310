.academicDbTeachersRank-mainDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.academicDbTeachersRank-gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
    grid-auto-rows: minmax(100px, 264px);
    gap: 16px;
}

.academicDbTeachersRank-box{
    display: flex;
    width: fit-content;
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-md);
    background-color: var(--color-base-light);
    padding: 16px;
}

.academicDbTeachersRank-boxText{
    font-size: var(--fs-h6);
    line-height: calc(var(--fs-h6)*1.3);
    color: var(--color-text-primary);
}

.academicDbTeachersRank-averageNumber-green{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
    background-color: var(--color-badge-green);
    color: var(--color-text-tertiary);
    padding: 4px 16px;
    border-radius: var(--border-radius-md);
}
.academicDbTeachersRank-averageNumber-yellow{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
    background-color: var(--color-badge-yellow);
    color: var(--color-text-tertiary);
    padding: 4px 16px;
    border-radius: var(--border-radius-md);
}
.academicDbTeachersRank-averageNumber-red{
    font-size: var(--fs-small);
    line-height: calc(var(--fs-small)*1.3);
    background-color: var(--color-badge-red);
    color: var(--color-text-tertiary);
    padding: 4px 16px;
    border-radius: var(--border-radius-md);
}

.academicDbTeachersRank-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 100px;
    max-height: 264px;
    overflow-x: hidden;
    padding: 10px;
    background-color: var(--color-base-light);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow);
}

.academicDbTeachersRank-card-title {
    font-size: var(--fs-h4);
    color: var(--color-text-primary);
}

.academicDbTeachersRank-stack-wrapper {
    overflow-y: auto;
    min-height: 100px;
    max-height: 264px;
}

.academicDbTeachersRank-stack-wrapper::-webkit-scrollbar {
    width: 8px;
}

.academicDbTeachersRank-stack-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.academicDbTeachersRank-stack-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.academicDbTeachersRank-card-rowItem:hover {
    background-color: var(--color-base);
}

.academicDbTeachersRank-card-bodyText {
    font-size: var(--fs-small);
    color: var(--color-text-primary);
}
