.AcademicDbUsers-mainDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 16px 0;
}

.AcademicDbUsers-ranks-header{
    font-size: var(--fs-h3);
    line-height: calc(var(--fs-h3)*1.2);
}