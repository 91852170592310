.academicDbUsersPieChart--mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-md);
  background-color: #fff;
  gap: 32px;
}

.academicDbUsersPieChart--titleDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 8px;
  gap: 8px;
}

.academicDbUsersPieChart--contentDiv{
  display: flex;
  flex-direction: row;
  gap: 64px;
}

.academicDbUsersPieChart--totalsDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .academicDbUsersPieChart--contentDiv{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}